import React, { useContext } from "react"
import classnames from "classnames"

/* Import Global Context */
import ColorContext from "~context/color"

/* Import Global Components */
import RightPointer from "~components/icon/normal/rightPointer"

/* Import Local Components */
import MuxVideo from "./components/muxVideo"

/* Import Local Styles */
import "./intellectual-property.css"

/* Import Local Assets */
import PlaceholderLogoSrc from "./assets/placeholder-logo.png"
import PlaceholderHeroSrc from "./assets/placeholder-hero.png"

const IntellectualProperty = ({
  children,
  logo = PlaceholderLogoSrc,
  image = PlaceholderHeroSrc,
  video,
  url,
}) => {
  const [color] = useContext(ColorContext)
  return (
    <div className="intellectual-property">
      <div className="intellectual-property-information">
        <img
          className={classnames("logo", color.background)}
          src={logo}
          alt=""
        />
        <p className="description">{children}</p>
        {url ? (
          <a href={url} target="_blank" rel="noreferrer" className="explore">
            EXPLORE{" "}
            <span>
              <RightPointer />
            </span>
          </a>
        ) : null}
      </div>
      {video ? (
        <div className="intellectual-property-video">
          <MuxVideo
            playbackId={video.asset.playbackId}
            thumbTime={video.asset.thumbTime ? video.asset.thumbTime : 0}
            autoplay={true}
          />
        </div>
      ) : (
        <div className="intellectual-property-image">
          <img
            className="hero"
            src={image && image.asset ? image.asset.url : ""}
            alt=""
          />
        </div>
      )}
    </div>
  )
}
export default IntellectualProperty
