import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"

/* Import Local Styles */
import "./ip-list.css"

const IpList = ({ children }) => (
  <ReactFullpage
    licenseKey="F7C8C209-27E347B6-B8D32569-EADB4BA4"
    scrollHorizontallyKey="cHVwcHlsb3ZlLmFnZW5jeV9TU0FjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDUyYjI="
    offsetSectionsKey="cHVwcHlsb3ZlLmFnZW5jeV9yaEliMlptYzJWMFUyVmpkR2x2Ym5NPTg0Zg=="
    fadingEffectKey="cHVwcHlsb3ZlLmFnZW5jeV80eFlabUZrYVc1blJXWm1aV04wZkls"
    loopTop={true}
    loopBottom={true}
    continuousVertical={true}
    scrollingSpeed={700}
    touchSensitivity={15}
    render={({ state, fullpageApi }) => {
      return (
        <ul className="fullpage-wrapper intellectual-properties">{children}</ul>
      )
    }}
  />
)

export default IpList
