import React from "react"

/* Import Global Components */
import SmallUpArrowSvg from "~components/icon/normal/smallUpArrow"

/* Import Local Styles */
import "./previous-ip-button.css"

const PreviousIpButton = () => {
  const goToPreviousSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSectionUp()
    }
  }

  return (
    <div className="previous-ip-button">
      <button onClick={goToPreviousSlide}>
        <SmallUpArrowSvg />
      </button>
    </div>
  )
}

export default PreviousIpButton
