import React from "react"

/* Import Global Components */
import SmallDownArrowSvg from "~components/icon/normal/smallDownArrow"

/* Import Local Styles */
import "./next-ip-button.css"

const NextIpButton = () => {
  const goToNextSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSectionDown()
    }
  }

  return (
    <div className="next-ip-button">
      <button onClick={goToNextSlide}>
        <SmallDownArrowSvg />
      </button>
    </div>
  )
}

export default NextIpButton
