import React from "react"
import classnames from "classnames"

/* Import Local Styles */
import "./ip-list-item.css"

const IpListItem = ({ children, className = "" }) => (
  <li className={classnames("section ip", className)}>{children}</li>
)

export default IpListItem
