import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

/* Import Global Components */
import Page from "~components/page"
import FadeInWords from "~components/animations/fadeInWordsSequentially"

/* Import Local Components */
import IntellectualProperty from "./components/intellectualProperty/_intellectualProperty"
import PreviousIpButton from "./components/previousIpButton/_previousIpButton"
import IpList from "./components/ipList/_ipList"
import IpListItem from "./components/ipListItem/_ipListItem"
import NextIpButton from "./components/nextIpButton/_nextIpButton"

/* Import Local Styles */
import "./ip.css"

const IP = ({ data: { sanityIntellectualPropertySettings: ip } }) => {
  return (
    <Page className="ip">
      <PreviousIpButton />
      <IpList>
        <IpListItem>
          <div className="ip-description">
            <div>
              <FadeInWords
                copy={ip.description}
                delay={100}
                transitionDuration={250}
              />
            </div>
          </div>
        </IpListItem>
        {ip.intellectualProperties.map(property => (
          <IpListItem key={property._id}>
            <IntellectualProperty
              logo={property.logo.asset.url}
              age
              video={property.featuredVideo ? property.featuredVideo : null}
              image={property.featuredImage ? property.featuredImage : null}
              url={property.url ? property.url : null}
            >
              <BlockContent blocks={property._rawDescription} />
            </IntellectualProperty>
          </IpListItem>
        ))}
      </IpList>
      <NextIpButton />
    </Page>
  )
}

export default IP

export const query = graphql`
  query IntellectualPropertiesQuery {
    sanityIntellectualPropertySettings(
      _id: { eq: "intellectualPropertySettings" }
    ) {
      _id
      description
      intellectualProperties {
        _id
        _rawDescription(resolveReferences: { maxDepth: 10 })
        featuredVideo {
          asset {
            playbackId
            thumbTime
          }
        }
        featuredImage {
          asset {
            url
          }
        }
        logo {
          asset {
            _id
            url
          }
        }
        url
      }
    }
  }
`
